import styled from "@emotion/styled"
import React from "react"
import { theme } from "../../utils/theme"
import { scale } from "../../utils/typography"
import { List } from "../basic"
import { reversedLink } from "../styles"
import { Category } from "./category"
import { Tag } from "./tag"

const Tags = ({ className, tags }) => {
  return (
    <List
      className={className}
      values={tags}
      component={({ value }) => <Tag value={value} />}
    />
  )
}

const Categories = ({ className, categories }) => {
  return (
    <List
      className={className}
      values={categories}
      component={({ name }) => <Category value={name} />}
    />
  )
}

export const CategoriesList = styled(Categories)`
  ${scale(-1 / 4)}
  text-transform: uppercase;
  font-weight: ${theme.fonts.body.weights.bold};
`

export const TagsList = styled(Tags)`
  ${scale(-1 / 6)};
  font-weight: ${theme.fonts.body.weights.light};
  li {
    margin-bottom: 0;
  }
  a {
    ${reversedLink({ color: theme.colors.secondary })}
  }
`

export { ViewAllTagsButton } from "./view-all-tags-button"

export { TagPills } from "./tag-pills"
