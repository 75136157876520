import React from "react"
import { FaTags } from "react-icons/fa"
import styled from "@emotion/styled"
import lookingForMorePortrait from "../../../content/assets/looking-for-more.svg"
import lookingForMoreLandscape from "../../../content/assets/looking-for-more-landscape.svg"
import { Button } from "../basic"

const Image = styled.img`
  display: block;
  &.looking-for-more-portrait {
    width: 12rem;
    margin-bottom: 0.25rem;
  }

  &.looking-for-more-landscape {
    width: 18rem;
    margin: 0 2rem 0 0;
  }
`

const Portrait = styled.div``

const Landscape = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .looking-for-more-portrait {
    display: none;
  }

  @media (max-width: 630px) {
    flex-direction: column;

    .looking-for-more-portrait {
      display: block;
    }

    .looking-for-more-landscape {
      display: none;
    }
  }
`

export const ViewAllTagsButton = ({
  className,
  isLandscape = false,
  text = "View all Tags",
}) => {
  const Container = isLandscape ? Landscape : Portrait

  return (
    <Container className={className}>
      {isLandscape && (
        <Image
          className="looking-for-more-landscape"
          src={lookingForMoreLandscape}
          alt="Looking for more?"
        />
      )}
      <Image
        className="looking-for-more-portrait"
        src={lookingForMorePortrait}
        alt="Looking for more?"
      />
      <Button to="/tags" icon={FaTags}>
        {text}
      </Button>
    </Container>
  )
}
