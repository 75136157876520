import { useStaticQuery, graphql } from "gatsby"
import { useDefaultAuthor } from "gatsby-theme-grape-blog/src/hooks/use-authors"

export const useBio = () => {
  const {
    file: {
      childImageSharp: { avatar },
    },
  } = useStaticQuery(graphql`
    query BioQuery {
      file(absolutePath: { regex: "/profile-pic.jpg/" }) {
        childImageSharp {
          avatar: gatsbyImageData(width: 40, height: 40, layout: FIXED)
        }
      }
    }
  `)

  const author = useDefaultAuthor()

  return { author, avatar }
}
