import React from "react"
import { Helmet } from "react-helmet"

export const MailchimpPopup = () => (
  <Helmet>
    {/* Mailchimp signup popup */}
    <script id="mcjs">
      {`!function(c,h,i,m,p){m=c.createElement(h),p=c.getElementsByTagName(h)[0],m.async=1,m.src=i,p.parentNode.insertBefore(m,p)}(document,"script","https://chimpstatic.com/mcjs-connected/js/users/1b164d82737ab1d5808bbd2b4/16d76419a49eb60fc2486aab5.js");`}
    </script>
  </Helmet>
)
