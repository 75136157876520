import styled from "@emotion/styled"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import { useBio } from "../hooks/use-bio"
import { rhythm, scale } from "../utils/typography"

const Container = styled.div`
  ${scale(-1 / 6)};
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;

  p {
    margin-top: ${rhythm(1 / 2)};
    margin-bottom: 0;
    text-align: center;
  }
`

const Image = styled(GatsbyImage)`
  border-radius: 50%;
`

const Bio = ({ className, content }) => {
  const { author, avatar } = useBio()

  const bio =
    content ||
    (() => (
      <>
        <p>by Alex Mącznik PhD</p>
        <a href={author.social.instagram}>Say Hi on Insta</a>
      </>
    ))

  return (
    <Container className={className}>
      <Image image={avatar} alt={author.displayName} />
      {bio({ author })}
    </Container>
  )
}

export default Bio
